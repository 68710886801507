html{
	@if $scale {
		font-size: resize(10px);
	} @else {
		font-size: 10px;
	}
	font-family: $main-font;
	font-weight: 400;
	margin-top: 0 !important;
	background-color: $main-bg-colour;
	//overflow-x: hidden;

	// @media (max-width: $md){
		
	// 	&.menu-open{
	// 		overflow: hidden;
	// 	}
	// }

	@media (max-width: $md){
		font-size: 10px;
	}

	@media (max-width: 410px){
		font-size: 8.5px;
	}

	@media (max-width: 390px){
		font-size: 7px;
	}

	@media (max-width: 340px){
		font-size: 6.5px;
	}

	&#BTT{
		margin-top: 0 !important;
	}

	&.search-open{
		overflow: hidden;
	}
}

body{
	color: $colour;
	@include font-size(20);
	@include line-height(20,28);
	font-weight: 400;
	position: relative;
	//overflow: hidden;
	
	&::-webkit-scrollbar{
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:before,
	&:after,
	*{

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	*{
		&::-webkit-scrollbar{
			display: none;
		}
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
		&:before,
		&:after,
		*{
	
			&::-webkit-scrollbar {
				display: none;
			}
	
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}
	}
}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 400;
	margin-bottom: 1.4em;
	@include font-size(20);
	@include line-height(20,28);

	sub, 
	sup{
		font-size: 60%;
	}

	a{
		font-size: inherit;
		text-decoration: none;
		font-weight: inherit;
		color: $gold;

		&:hover,
		&:focus-visible{
			color: $green;
			text-decoration: none;
		}
	}

	strong{
		font-weight: 500;
		color: inherit;
	}

	em{
		font-style: normal;
		font-family: $alt-font2;
		font-size: 105.7692%; 
		@include line-height(55,58);
		color: $gold;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	font-family: $alt-font;
	margin-bottom: .45em;
	font-weight: 400;

	img{
		display: inline-block;
		margin: 0 .113em!important;
	}
}

h1,
h2,
h3,
h4,
h5,
h6{
	color: $green;
}

h1{
	@include font-size(58);
	@include line-height(58,68);
	margin-bottom: .51em;

	&:has(+ hr){
		margin-bottom: .76em;
	}

	em{
		font-size: 134.4827%; 
		@include line-height(78,68);
	}

	@media (max-width: $md){
		@include font-size(44);
		@include line-height(44,50);
		margin-bottom: .63em;

		em{
			font-size: 140.9090%; 
			@include line-height(62,50);
		}
	}
}

h2{
	@include font-size(52);
	@include line-height(52,58);

	em{
		font-size: 105.7692%; 
		@include line-height(55,58);
	}
}

h3{
	@include font-size(44);
	@include line-height(44,50);
	margin-bottom: .63em;

	em{
		font-size: 140.9090%; 
		@include line-height(62,50);
	}
}

h4{
	@include font-size(32);
	@include line-height(32,36);
	margin-bottom: .29em;

	em{
		font-size: 162.5%; 
		@include line-height(52,40);
	}
}

h5{
	@include font-size(24);
	@include line-height(24,28);
}

h6{
	@include font-size(18);
	@include line-height(18,24);
}

p{
	@include font-size(20);
	@include line-height(20,28);

	&.small{
		@include font-size(18);
		@include line-height(18,24);
	}

	&.large{
		@include font-size(22);
		@include line-height(22,30.8);
	}
}

mark{
	background-color: $gold;
	font-weight: 400;
	box-shadow: .05em 0 0 $gold, -.05em 0 0 $gold;
}

strong{
	font-weight: 500;
}

blockquote{
	@include font-size(32);
	@include line-height(32,40);
	font-family: $alt-font;
	margin: 0 0 1.35em;
	font-weight: 500;
	padding: 0;
	font-style: normal;
	color: $colour;
	background-position: 50% 0;
	padding: 3rem 0 ;
	position: relative;
	z-index: 1;
	text-transform: none;
	
	&:before,
	&:after{
		position: absolute;
		font-size: 503.125%;
		opacity: .1;
	}

	&:before{
		content: '“';
		top: -.14em;
		left: -.01em;
	}

	&:after{
		content: '”';
		bottom: -.69em;
		right: .33em;
	}

	&:has(~ .by){
		margin-bottom: -1em;
	}

	~ .by{
		font-weight: 400;
		margin-bottom: .05em;
		@include font-size(42);
		@include line-height(42,60);
		font-family: $alt-font2;
		
		em{
			font-style: normal;
		}

	}

	p{
		font-size: inherit;
		line-height: inherit;
		font-family: inherit;
		margin-bottom: 0!important;
	}
}

button{
	box-shadow: none;
	background-color: transparent;
	border: none;
	padding: 0;
}

button,
a{
	font-size: inherit;
	color: $colour;
	font-weight: 400;
	transition: $time;
	cursor: pointer;
	text-decoration: underline;

	&:before,
	&:after,
	*:before,
	*:after,
	*{
		transition: $time;
	}

	&:hover,
	&:focus-visible{
		color: $black;
		text-decoration: underline;
	}
}

hr{
	border: 0;
	width: 8.727rem;
	height: 5.751rem;
	background-size: 100% auto;
	margin: 3.3rem auto 3.3rem;
	background-image: url(../images/hr.svg);
	background-repeat: no-repeat;

	&:last-child{
		margin-bottom: 0;
	}
}

address{
	font-style: normal;
	@include font-size(20);
	@include line-height(20,28);
	color: $colour;
	font-weight: 400;
	margin-bottom: 1.3em;
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	font-size: 0;
	margin-bottom: .7em;

	li,
	dt,
	dd{
		@include font-size(20);
		@include line-height(20,28);
		margin-bottom: 0;

		@media (max-width: $md){
			@include font-size(18);
			@include line-height(18,28);
		}

		a{
			font-size: inherit;
		}
	}

	dd{
		margin-bottom: 20px;
	}
}

.acf_content ol,
.acf_content ul,
.acf_content dl,
main ol,
main ul,
main dl{
	@include font-size(20);
	margin-bottom: .7em;

	@media (max-width: $md){
		@include font-size(18);
	}
}

ul{
	list-style-position: inside;
	padding-left: 0;

	&.no-bullet{
		list-style: none;

		li{
			padding-left: 0;

			&:before{
				display: none;
			}
		}
	}

	li{
		padding-left: 1.5em;
		list-style: none;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			left: 0em;
			top: .7em;
			width: .4em;
			height: .4em;
			border-radius: 1em;
			background-color: $colour;
		}

		ul{
			margin-top: 1em;
			margin-bottom: 1em;

			li{

				&:before{
					border-radius: 0;
				}
			}
		}
	}
}

ul.side-menu,
ul.sticky-menu,
ul.filter-items,
ul.woocommerce-PaymentMethods,
ul.woocommerce-shipping-methods,
ul.woocommerce-order-overview,
ul.wc_payment_methods,
ul.select2-results__options,
.woocommerce-MyAccount-navigation ul,
ul.woocommerce-error,
ul.matching-items,
ul.post-categories,
ul.tabs,
ul.small-menu,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.g_fields,
ul.slick-dots,
ul.social-menu,
ul.flex,
ul.slick{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0;
	counter-reset: section;

	li{
		list-style: none;
		position: relative;

		&:before{
			counter-increment: section;
			content: counter(section)". ";
			right: calc(100% - .5em);
			min-width: 1.5em;
			display: inline-block;
		}
	}
}

html body table{
	margin-bottom: 0;
	border: none;
	width: 100%!important;
	background-color: transparent;
	text-align: left;
	border: .1rem solid $gold;
	table-layout: fixed;
	
	*{
		vertical-align: top;
	}

	thead{

		tr{
			background-color: rgba($gold, .15);
			border-bottom: .1rem solid $gold;
		}

		th{
			padding: 2.1rem 2.2rem;
			@include font-size(24);
			line-height: 1.7;
			font-family: $alt-font;
			color: $green;
			font-weight: 500;

			+ th{
				border-left: .1rem solid $gold;
			}

			&:first-of-type{
				background-color: rgba($gold, .1);
			}

			.small{
				@include font-size(18);
				@include line-height(18,28);
				color: $blue;
				font-family: $main-font;
				font-weight: 400;
			}
		}
	}

	tbody{

		tr{
			border-bottom: .1rem solid $gold;

			&:last-of-type{
				border-bottom: none;
			}

			&:nth-of-type(even){
				background-color: rgba($gold, .05);
			}
		}

		td{
			padding: 2.7rem 2.3rem;
			color: $blue;

			+ td{
				border-left: .1rem solid $gold;
			}

			&:first-of-type{
				background-color: rgba($gold, .1);
				font-weight: 500;
			}
		}
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(16);
		@include line-height(16,28);
		font-weight: 400;
		color: $colour;

		@media (max-width: $md){
			
		}

		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 1.1rem;

			@media (max-width: $md){
				padding-top: .3rem;
			}
		}
	}
}

svg,
p.p-img a,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 0em 0 1.55em;
	}

	&.alignright {
		float:right;
		margin: 0em 0 1.55em 1.55em;
	}

	&.alignleft {
		float:left;
		margin: 0em 1.55em 1.55em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 0em auto 1.55em;
	}
}

a img.alignright {
	float:right;
	margin: 0em 0 1.55em 1.55em;
}

a img.alignleft {
	float:left;
	margin: 0em 1.55em 1.55em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 0em auto 1.55em;
}

::selection {
	background: darken($gold, 5%);
	color: $white;
}

::-moz-selection {
	background: darken($gold, 5%);
	color: $white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
input[name][name][name]:focus-visible,
textarea[name][name][name]:focus-visible,
select[name][name][name]:focus-visible{
	border-color: $gold!important;
	color: $gold!important;
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input.mage-error:focus-visible,
select.mage-error:focus-visible,
textarea.mage-error:focus-visible,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	margin-left: 0px;
	text-align: left;
}

pre{
	font-size: resize(10px);
	@include font-size(10);
}
