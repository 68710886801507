.calendar {
	padding-top: 150px;

	@media (max-width: $md) {
		padding-top: 80px;
	}

	&__cms-content {
		margin: 0 auto;
		max-width: 480px;
		padding: 0 20px;
	}

	&__module {
		max-width: 990px;
		background-color: $cream;
		margin: 60px auto 130px;

		@media (max-width: $md) {
			margin-top: 40px;
			margin-bottom: 70px;
		}
	}

	&__custom-nav {
		display: flex;
		justify-content: space-between;
		padding: 65px 60px 45px 60px;
		align-items: center;

		@media (max-width: $lg) {
			padding: 60px 15px 15px 15px;
		}
	}

	&__nav-options {
		display: flex;
		gap: 35px;
		
		@media (max-width: $lg) {
			gap: 10px;
		}

		select {
			width: 220px;
			font-size: 2.4rem;
			color: $gold;
			height: 5.4rem;

			@media (max-width: $md) {
				width: 110px
			}

			option {
				font-size: 2rem;
			}
		}
	}
 

	&__nav-btn {
		white-space: nowrap;
		cursor: pointer;
		user-select: none;
		margin-top: 10px;

		span, svg {
			vertical-align: middle;
			display: inline-block;
		}

		svg {
			@media (max-width: $lg) {
				width: 46px;
			}
		}

		span {
			font-family: $font-scotch;
			font-size: 24px;
			color: $green;
			transition: color 120ms;

			@media (max-width: $lg) {
				display: none
			}
		}

		&.prev span {
			margin-left: 16px;
		}
		&.next span {
			margin-right: 16px;
		}

		&:hover span {
			color: $gold;
		}
	}

	&__app {
		display: flex;
		flex-direction: column;
		box-shadow: none;
		color: $green;

		.calendar-table-currentdate {
			display: none;
		}

		#eventListToggler {
			display: none;
		}

		.calendar-sidebar {
			display: none;
		}


		&.event-hide .calendar-inner {
			max-width: 100%;
		}

		.calendar-inner {
			margin-left: 0;
			background-color: $cream;
			padding: 0 60px 70px;

			@media (max-width: $lg) {
				padding: 0 15px 40px;
			}
		}

		.calendar-events {
			z-index: 100;
			display: none;
		}

		.calendar-table {
			border: none;
		}

		/* Columns & rows */
		tbody tr:nth-of-type(even),
		tbody td:first-of-type {
			background-color: transparent;
		}

		tbody td {
			padding: 0;
		}
		tbody td + td {
			border-left-color: $cream;
		}
		tbody tr {
			border-bottom-color: $cream;
		}


		tr.calendar-header {
			border-bottom: solid 1px #D1D2CE;
		}

		tr.spacing td {
			padding: 26px 0 0 0;
		}

		tr.calendar-header .calendar-header-day {
			text-transform: uppercase;
			border: none;
			color: $green;
			font-family: $font-freight;
			font-weight: 500;
			padding: 22px 5px;
			text-align: center;
			background-color: transparent;

			@media (max-width: $lg) {
				padding: 14px 2px;
				line-height: 1;
				font-size: 18px;
			}
		}

		tr.calendar-body .calendar-day {
			padding: 0;

			.day {
				font-family: $font-scotch;
				font-size: 3.2rem;
				width: 100%;
				border-radius: 0;
				height: 90px;
				line-height: 90px;
				padding: 0;
				color: $white;
				background-color: $green;
				border: 0px solid transparent;
				transition: none;

				@media (max-width: $lg) {
					width: auto;
					height: 72px;
					line-height: 72px;
					font-size: 2.4rem;
				}

				@media (max-width: $md) {
					height: 41px;
					line-height: 41px;
				}

				.day-num {
					position: relative;
					z-index: 3;
				}

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					display: block;
					z-index: 1;
					width: 100%;
					height: 100%;
				}

				&.hover-booked::before {
					background-color: #B69556;
				}
				&.hover-checkout::before {
					clip-path: polygon(100% 0, 0% 100%, 0 0);
					background-color: #B69556;
				}
				&.hover-checkin::before {
					clip-path: polygon(100% 0, 0% 100%, 100% 100%);
					background-color: #B69556;
				}
				&.hover-unavailable {
					background-color: #4A4A4A;
					cursor: default;
					color: $white !important;

					&:active {
						transform: scale(1) !important;
					}
				}

				.event-indicator {
					left: 0;
					top: 0;
					transform: none;
					width: 100%;
					height: 100%;
					z-index: 2;

					div[class^="type"] {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border-radius: 0;
					}

					.type-checkout {
						clip-path: polygon(100% 0, 0% 100%, 0 0);
					}

					.type-checkin {
						clip-path: polygon(100% 0, 0% 100%, 100% 100%);
					}
				}
			}
		}
	}

	&__legend {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		justify-content: center;
		gap: 25px;
		padding: 0 15px 74px;

		@media (min-width: $md + 1) {
			grid-template-columns: 1fr 1fr 1fr;
			padding: 0 60px 100px;
		}

		div {
			white-space: nowrap;

			&:before {
				content: '';
				display: inline-block;
				width: 40px;
				height: 30px;
				margin-right: 18px;
				background-size: cover;
				vertical-align: middle;
				// background-color: 'to-set';
			}

			span {
				vertical-align: middle;
				color: $green;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-family: $font-freight;
				font-size: 1.8rem;
				font-weight: 500;
				line-height: 1;
			}

			&.legend-pending {
				&::before {
					background-color: #A8A8A8; 
				}
			}

			&.legend-unavailable {
				&::before {
					background-color: #1A2C2B;
				}
			}

			&.legend-available {
				&::before {
					background-color: $green;
				}
			}

			&.legend-booked {
				&::before {
					background-color: #C8AE7E;
				}
			}

			&.legend-checkout {
				&::before {
  					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDQwIDMwIj4NCiAgPGcgaWQ9Ikdyb3VwXzEyMDciIGRhdGEtbmFtZT0iR3JvdXAgMTIwNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExNDEgLTE1MTkpIj4NCiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzg5MSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgODkxIiB3aWR0aD0iMzkiIGhlaWdodD0iMzAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExNDIgMTUxOSkiIGZpbGw9IiMwYjRiNDYiLz4NCiAgICA8cGF0aCBpZD0iUGF0aF83MDAiIGRhdGEtbmFtZT0iUGF0aCA3MDAiIGQ9Ik0wLDBIMzkuNjdMMCwyOS44NjNaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTQxIDE1MTkuMTM3KSIgZmlsbD0iI2M4YWU3ZSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==")
				}
			}

			&.legend-checkin {
				&::before {
  					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDQwIDMwIj4NCiAgPGcgaWQ9Ikdyb3VwXzEyMTUiIGRhdGEtbmFtZT0iR3JvdXAgMTIxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwMzUgLTE0MjIpIj4NCiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzg5MSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgODkxIiB3aWR0aD0iMzkiIGhlaWdodD0iMzAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwMzYgMTQyMikiIGZpbGw9IiNjOGFlN2UiLz4NCiAgICA8cGF0aCBpZD0iUGF0aF83MDAiIGRhdGEtbmFtZT0iUGF0aCA3MDAiIGQ9Ik0wLDBIMzkuNjdMMCwyOS44NjNaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDM1IDE0MjIuMTM3KSIgZmlsbD0iIzBiNGI0NiIvPg0KICA8L2c+DQo8L3N2Zz4NCg==")
				}
			}
		}
	}
}