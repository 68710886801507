//!!SCALE SETTINGS
$scale: false; //false, true
$vw-viewport: 1440; //If false does nothing. if true set this to the width of the design sent over

//!!GRID SETTINGS
$grid-gutter-widthWO: 35 !default; //most common gutter width

//!!PAGE WIDTH
$base-widthWO:		1244; //most common container width

@font-face {
	font-family: 'FreightSansPro';
	src: url('../fonts/FreightSansProBook-Regular.woff2') format('woff2'),
		url('../fonts/FreightSansProBook-Regular.woff') format('woff'),
		url('../fonts/FreightSansProBook-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FreightSansPro';
	src: url('../fonts/FreightSansProBook-Italic.woff2') format('woff2'),
		url('../fonts/FreightSansProBook-Italic.woff') format('woff'),
		url('../fonts/FreightSansProBook-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FreightSansPro';
	src: url('../fonts/FreightSansProMedium-Regular.woff2') format('woff2'),
		url('../fonts/FreightSansProMedium-Regular.woff') format('woff'),
		url('../fonts/FreightSansProMedium-Regular.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Scotch Display Roman';
	src: url('../fonts/ScotchDisplay-Roman.woff2') format('woff2'),
		url('../fonts/ScotchDisplay-Roman.woff') format('woff'),
		url('../fonts/ScotchDisplay-Roman.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Portrait Script Alts';
	src: url('../fonts/PortraitScript-Alts.woff2') format('woff2'),
		url('../fonts/PortraitScript-Alts.woff') format('woff'),
		url('../fonts/PortraitScript-Alts.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

//!!COLOURS
$red:				#CE2E45;

$green:				#0B4B46;

$cream:				#F6F0E9;

$blue:				#002A35;
$blue-dark:			#030708;

$gold:				#B69556;

$white:				#FBF6F4;
$black:				#000000;

$colour: 			$blue;
$main-bg-colour:	$white;

//!!FONTS
$font-freight:		'FreightSansPro', sans-serif;
//book - 400
//medium - 500

$font-scotch:		'Scotch Display Roman', sans-serif;
//bold - 400

$font-portrait:		'Portrait Script Alts', sans-serif;
//bold - 400

$main-font:			$font-freight;
$alt-font:			$font-scotch;
$alt-font2:			$font-portrait;

//!!SITE TRANSITIONS
$time:				.3s;

//BREAK POINTS
$xl:				$base-widthWO - 1px ; //$base-width + - 1px
$lg:				992px - 1px; //992px - 1px
$md:				769px - 1px; //769px - 1px
$sm:				576px - 1px; //576px - 1px

//xl: $base-width
//lg: 992px
//md: 768px
//sm: 576px